import { NOTIFICATION_CLEAR, NOTIFICATION_TRIGGER } from "./actionTypes";

const notificationInitialState = {
    open: false,
};

const notification = (state = notificationInitialState, action) => {
    switch (action.type) {
        case NOTIFICATION_TRIGGER:
            return {
                open: true,
                notificationType: action.payload.notificationType,
                notificationHeader: action.payload.notificationHeader,
                notificationMessage: action.payload.notificationMessage,
            };
        case NOTIFICATION_CLEAR:
            return { ...notificationInitialState };
        default:
            return state;
    }
};

export const notificationInfoSelector = (state) => ({
    open: state.open,
    notificationType: state.notificationType,
    notificationHeader: state.notificationHeader,
    notificationMessage: state.notificationMessage,
});

export default notification;
