import axios from 'axios';
import { getSessionToken } from '@shopify/app-bridge-utils';

import { LOCAL_JWT_TOKEN } from 'src/lib/constants';

class Api {
    constructor() {
        this.shopifyApp = null;
        this.client = axios.create({
            baseURL: `${CONFIG.apiUrl}/api`,
        });
    }

    setShopifyApp(app) {
        this.shopifyApp = app;
    }

    async getAuthToken() {
        let authToken = null;
        if (this.shopifyApp) {
            console.log("Using Shopify session token for auth");
            try {
                authToken = await getSessionToken(this.shopifyApp);
            } catch (err) {
                console.error(err);
            }
        } else if (typeof window !== "undefined") {
            try {
                authToken = window.localStorage?.getItem(LOCAL_JWT_TOKEN);
            } catch (err) {
                console.log(
                    `localStorage may not be available, can't get local JWT token`
                );
            }
        }

        return authToken;
    }

    async request(config) {
        const requestConfig = {
            ...config,
            headers: {
                ...(config.headers || {}),
            },
        };

        let authToken = null;
        if (this.shopifyApp) {
            console.log("Using Shopify session token for auth");
            try {
                authToken = await getSessionToken(this.shopifyApp);
            } catch (err) {
                console.error(err);
            }
        } else if (typeof window !== "undefined") {
            try {
                authToken = window.localStorage?.getItem(LOCAL_JWT_TOKEN);
            } catch (err) {
                console.log(
                    `localStorage may not be available, can't get local JWT token`
                );
            }
        }

        if (authToken) {
            requestConfig.headers["Authorization"] = `Bearer ${authToken}`;
        }


        return this.client.request(requestConfig);
    }
}

export default new Api();
