import React, { useState, useEffect, Fragment } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { connect } from "react-redux";
import { Container, Form, Nav, Navbar } from "react-bootstrap";
import clsx from "clsx";
import Select from "react-select";
import LogoYellow from "public/assets/logo-yellow.svg";
import useScreenSize from "src/hooks/useScreenSize";

import Api from "src/data/api";
import styles from "src/components/layout/appLayout.module.scss";
import { LOCAL_JWT_TOKEN } from "src/lib/constants";

function Sidebar({ user }) {
    const { innerWidth } = useScreenSize();
    const breakpoint = 992;
    const router = useRouter();
    const [selectedRetailer, setSelectedRetailer] = useState(null);
    const [retailerList, setRetailerList] = useState([]);
    const [isLocalLogin, setIsLocalLogin] = useState(false);
    const [isExpanded, setIsExpanded] = useState(true);

    useEffect(() => {
        try {
            setIsLocalLogin(
                typeof window !== "undefined" &&
                    !!window.localStorage?.getItem(LOCAL_JWT_TOKEN)
            );
        } catch (err) {
            // localStorage is not available, assume Shopify login is in use
        }
    }, []);

    useEffect(() => {
        if (user.role !== "admin") {
            return;
        }

        (async () => {
            try {
                const { data: retailers } = await Api.request({
                    url: "/admin/masquerade/list",
                });
                setRetailerList(retailers);
                const retailer = retailers.find(
                    (x) => x.retailer_id == user.retailer_id
                );
                setSelectedRetailer({
                    value: retailer.retailer_id,
                    label: retailer.name,
                });
            } catch (err) {
                console.error("Failed to get retailers list");
            }
        })();
    }, [user.role]);

    const handleMasquerade = async (e) => {
        const retailerId = e.value;
        setSelectedRetailer({ ...e });

        try {
            const { data } = await Api.request({
                method: "POST",
                url: `/admin/masquerade/${retailerId}`,
            });

            window.localStorage.setItem(LOCAL_JWT_TOKEN, data.token);
            window.location.reload();
        } catch (err) {
            console.error("Failed to masquerade");
        }
    };

    const SidebarItem = ({ href, children }) => (
        <Link href={href} passHref>
            <Nav.Link
                className={`${styles.sidebarItem} ${
                    href === router.pathname ? styles.sidebarItemActive : ""
                }`}
            >
                {children}
            </Nav.Link>
        </Link>
    );

    const options = retailerList.map((retailer) => ({
        value: retailer.retailer_id,
        label: retailer.name,
    }));

    const handleToggle = (e) => {
        setIsExpanded(!isExpanded);
    };

    return (
        <Navbar
            className={
                isExpanded
                    ? styles.sidebar
                    : `${styles.sidebar} ${styles.sidebar_expanded}`
            }
            collapseOnSelect
            expand="lg"
            variant="dark"
        >
            <div
                onClick={handleToggle}
                className={
                    !isExpanded
                        ? `${styles.toggle} ${styles.toggle_expanded}`
                        : styles.toggle
                }
            >
                {isExpanded ? (
                    <i className="bi bi-chevron-double-left"></i>
                ) : (
                    <i className="bi bi-chevron-double-right"></i>
                )}
            </div>
            <div
                className={isExpanded ? styles.brandLogo : styles.hidden}
            ></div>
            <Navbar.Toggle
                className={!isExpanded && styles.hidden}
                aria-controls="responsive-navbar-nav"
            />
            <Navbar.Collapse
                className={isExpanded ? styles.navContainer : styles.hidden}
                id="responsive-navbar-nav"
            >
                <Nav
                    className={
                        isExpanded
                            ? `me-auto ${styles.navigation}`
                            : styles.hidden
                    }
                >
                    {user.role === "admin" && (
                        <Fragment>
                            <Form.Group
                                controlId="sidebar-masquerade-list"
                                className={styles.sidebarMasqueradeList}
                            >
                                <Form.Label>Masquerade as retailer:</Form.Label>

                                <Select
                                    className={styles.retailersList}
                                    options={options}
                                    value={selectedRetailer || ""}
                                    onChange={handleMasquerade}
                                    isSearchable={true}
                                ></Select>
                            </Form.Group>
                            <hr className={styles.divider} />
                        </Fragment>
                    )}

                    {!!user?.flags?.queues_enabled && (
                        <SidebarItem href="/queues">Queues</SidebarItem>
                    )}
                    <SidebarItem href="/">
                        {user.role === "admin" && "Retailer "}Calendar
                    </SidebarItem>
                    {user.role === "admin" && (
                        <SidebarItem href="/master-calendar">
                            Admin Calendar
                        </SidebarItem>
                    )}
                    {user.role !== "employee" && (
                        <SidebarItem href="/schedule">Schedule</SidebarItem>
                    )}

                    {!user.location_lock && user.role !== "employee" && (
                        <>
                            <SidebarItem href="/questionnaire">
                                Questionnaire
                            </SidebarItem>
                            <SidebarItem href="/settings">Settings</SidebarItem>

                            {user.role === "admin" && (
                                <SidebarItem href="/feedback">
                                    Feedback
                                </SidebarItem>
                            )}
                        </>
                    )}
                    {user.role !== "employee" && (
                        <SidebarItem href="/analytics">Analytics</SidebarItem>
                    )}
                    {!!user.flags?.employee_schedules_enabled && (
                        <SidebarItem href="/profile">Profile</SidebarItem>
                    )}
                    <hr className={styles.divider} />
                    {/* Logout should only be visible if using local login */}
                    {isLocalLogin ? (
                        <SidebarItem href="/logout">Logout</SidebarItem>
                    ) : (
                        <SidebarItem href="/shopify/welcome">
                            Getting Started
                        </SidebarItem>
                    )}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}

export default connect((store) => ({ user: store.user.data }))(Sidebar);
