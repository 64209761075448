import { combineReducers } from "redux";
import notification from "./notificationReducer";

import * as types from "./actionTypes";

const userLoggedIn = (state = false, action) => {
    switch (action.type) {
        case types.loadUserSuccess:
            return !!action.data;
        case types.loadUserFail:
            return false;
        case types.authLoginSuccess:
            return true;
        case types.authLogoutSuccess:
            return false;
        default:
            return state;
    }
};

const userLoading = (state = true, action) => {
    switch (action.type) {
        case types.loadUserSuccess:
            return false;
        case types.loadUserFail:
            return false;
        default:
            return state;
    }
};

const userData = (state = {}, action) => {
    switch (action.type) {
        case types.loadUserSuccess:
            return action.data;
        case types.authLoginSuccess:
            return action.data;
        case types.CUSTOMER_CREATION_SUCCESS:
            return { ...state, customerId: action.payload };
        case types.authLoginFail:
            return {};
        case types.loadUserFail:
            return {};
        case types.authLogoutSuccess:
            return {};
        default:
            return state;
    }
};

export default combineReducers({
    user: combineReducers({
        loggedIn: userLoggedIn,
        loading: userLoading,
        data: userData,
    }),
    notification,
});

export const customerIdSelector = (state) => state.data.customerId;
export const userEmailSelector = (state) => state.data.email;
export const isShopifyUserSelector = (state) => state.data.isShopifyUser;
