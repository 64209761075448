import React from 'react';

import BaseLayout from 'src/components/layout/baseLayout';
import Sidebar from 'src/components/layout/sidebar';
import styles from 'src/components/layout/appLayout.module.scss';

function AppLayout({ children }) {
    return (
        <BaseLayout>
            <div className={styles.pageWrapper}>
                <Sidebar />
                <div className={styles.pageContent}>
                    {children}
                </div>
            </div>
        </BaseLayout>
    );
}

export default AppLayout;
