import { useState, useContext, useRef, createContext } from "react";

export const ShopifyAppBridgeContext = createContext();
export function useShopifyAppBridge() {
    return useContext(ShopifyAppBridgeContext);
}

export function ShopifyAppBridgeProvider({ children }) {
    const [shop, setShop] = useState('');
    const ref = useRef(null);

    return (
        <ShopifyAppBridgeContext.Provider 
            value={{
                getAppBridge: () => ref.current,
                setAppBridge: (app) => ref.current = app,
                getShop: () => shop,
                setShop,
            }}
        >
            {children}
        </ShopifyAppBridgeContext.Provider>
    );
}
