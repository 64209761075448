import React from "react";
import { Toast, ToastBody, ToastHeader } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { clearNotification } from "src/redux/notificationActions";
import { notificationInfoSelector } from "src/redux/selectors";
import styles from "./Notification.module.scss";

const SECONDS_DELAY = 3000;

const Notification = ({ isNonAppPage }) => {
    const { open, notificationHeader, notificationMessage, notificationType } =
        useSelector(notificationInfoSelector);

    const dispatch = useDispatch();

    const handleNotificationClear = () => {
        dispatch(clearNotification());
    };

    return (
        <div
            className={`${styles.notification} ${styles[notificationType]} ${
                isNonAppPage ? styles.nonAppPage : ""
            }`}
        >
            <Toast
                style={{ border: "none", borderRadius: 0 }}
                show={open}
                onClose={handleNotificationClear}
                animation={false}
                autohide={true}
                delay={SECONDS_DELAY}
            >
                <ToastHeader style={{ justifyContent: "space-between" }}>
                    {notificationHeader}
                </ToastHeader>
                <ToastBody>{notificationMessage}</ToastBody>
            </Toast>
        </div>
    );
};

export default Notification;
