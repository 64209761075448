export const loadUserStart = "LOAD_USER-START";
export const loadUserSuccess = "LOAD_USER-SUCCESS";
export const loadUserFail = "LOAD_USER-FAIL";

export const authLoginStart = "AUTH_LOGIN-START";
export const authLoginSuccess = "AUTH_LOGIN-SUCCESS";
export const authLoginFail = "AUTH_LOGIN-FAIL";
export const authLogoutStart = "AUTH_LOGOUT-START";
export const authLogoutSuccess = "AUTH_LOGOUT-SUCCESS";
export const authLogoutFail = "AUTH_LOGOUT-FAIL";
export const CUSTOMER_CREATION_SUCCESS = "CUSTOMER_CREATION_SUCCESS";

// notification types
export const NOTIFICATION_TRIGGER = "NOTIFICATION_TRIGGER";
export const NOTIFICATION_CLEAR = "NOTIFICATION_CLEAR";
