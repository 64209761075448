const { NOTIFICATION_CLEAR, NOTIFICATION_TRIGGER } = require("./actionTypes");

const triggerNotification = (notificationInfo) => ({
    type: NOTIFICATION_TRIGGER,
    payload: notificationInfo,
});

const clearNotificationData = () => ({
    type: NOTIFICATION_CLEAR,
});

export const clearNotification = () => async (dispatch) =>
    dispatch(clearNotificationData());

export const showNotification =
    (notificationMessage, notificationType, notificationHeader) =>
    async (dispatch) => {
        dispatch(
            triggerNotification({
                notificationMessage,
                notificationType,
                notificationHeader,
            })
        );
    };
