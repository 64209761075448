import * as fromNotification from "./notificationReducer";
import * as fromUser from "./reducers";

// notification selectors
export const notificationInfoSelector = (state) =>
    fromNotification.notificationInfoSelector(state.notification);

export const customerIdSelector = (state) =>
    fromUser.customerIdSelector(state.user);

export const userEmailSelector = (state) =>
    fromUser.userEmailSelector(state.user);

export const isShopifyUserSelector = (state) =>
    fromUser.isShopifyUserSelector(state.user);
