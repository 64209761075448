import React, { useState } from "react";
import Head from "next/head";
import { wrapper as reduxWrapper } from "src/redux/store";
import { useRouter } from "next/router";

import AppLayout from "src/components/layout/appLayout";
import { ShopifyAppBridgeProvider } from "src/components/shopify/appBridge";
import Notification from "src/components/Shared/Notification";
import "src/components/layout/global.scss";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "bootstrap-icons/font/bootstrap-icons.css";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { QueryClient, QueryClientProvider } from "react-query";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const stripePromise = loadStripe(CONFIG.stripe.publishableKey);

// These pages should not be rendered with AppLayout
const nonAppPages = [
    "/login",
    "/reset-password",
    "/shopify",
    "/shopify/new-user",
    "/shopify/login",
    "/shopify/subscribe",
    "/zoom-installation",
    "/register-retailer",
    "/subscribe",
    "/subscription-complete",
    "/choose-subscription",
    "/customer-feedback",
    "/google-oauth2",
    "/documentation",
    "/enterprise-subscription-contact",
    "/pipedrive-installation",
    "/employee-register",
];

const App = ({ Component, pageProps }) => {
    const [queryClient] = useState(
        () =>
            new QueryClient({
                defaultOptions: {
                    queries: {
                        // With SSR, we usually want to set some default staleTime
                        // above 0 to avoid refetching immediately on the client
                        staleTime: 60 * 1000,
                    },
                },
            })
    );
    const router = useRouter();
    const isNonAppPage = nonAppPages.includes(router.pathname);
    const getLayout = !isNonAppPage
        ? (page) => <AppLayout>{page}</AppLayout>
        : (page) => page;

    return (
        <>
            <Head>
                <title>Tote Dashboard</title>
                <meta charSet="utf-8" />
                <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1, shrink-to-fit=no"
                />
            </Head>
            <Notification isNonAppPage={isNonAppPage} />
            <QueryClientProvider client={queryClient}>
                <ShopifyAppBridgeProvider>
                    <GoogleOAuthProvider
                        clientId={CONFIG.googleSecondary.clientId}
                    >
                        <Elements stripe={stripePromise}>
                            {getLayout(<Component {...pageProps} />)}
                        </Elements>
                    </GoogleOAuthProvider>
                </ShopifyAppBridgeProvider>
            </QueryClientProvider>
        </>
    );
};

export default reduxWrapper.withRedux(App);
