import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createWrapper, HYDRATE } from 'next-redux-wrapper';
import thunk from 'redux-thunk';

import reducers from './reducers';

const nextReduxReducer = (state = {}, action) => {
    if (action.type === HYDRATE) {
        const nextState = {
            ...state, // use previous state
            ...action.payload, // apply delta from hydration
        };
        return nextState;
    } else {
        return reducers(state, action);
    }
};

const makeStore = () => {
    return createStore(
        nextReduxReducer,
        undefined,
        composeWithDevTools(applyMiddleware(thunk))
    );
}

export const wrapper = createWrapper(makeStore, { debug: false });

